<template>
  <div>
    <div class="card">
      <div class="card-body">
        <h5 class="mb-4">
          <strong>Tambah/Ubah Data Program</strong>
        </h5>
        <a-form :form="form" @submit="findPatient">
          <a-form-item
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            label="Kode Pasien"
          >
            <a-input
              :disabled="submitting"
              placeholder="Masukkan Kode Pasien"
              v-decorator="[
                'code',
                {
                  rules: [
                    { required: true, message: 'Kode Pasien harus diisi!' },
                  ],
                },
              ]"
            />
            <button
              type="submit"
              class="btn btn-success px-5"
              :disabled="submitting"
            >
              Cari Pasien
            </button>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <div class="card" v-if="patientId">
      <div class="card-body">
        <h5 class="mb-4">
          <strong>Data Tes Laboratorium</strong>
        </h5>
        <a-form :form="form" @submit="findPatient">
          <a-form-item
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            label="Jenis Tes"
          >
            <a-select
              :disabled="submitting"
              v-decorator="[
                'testLabTypeId',
                {
                  rules: [
                    { required: true, message: 'Jenis Tes harus diisi!' },
                  ],
                },
              ]"
              placeholder="Jenis Tes"
            >
              <a-select-option
                v-for="testLabType in testLabTypeList"
                :key="testLabType.id"
                :value="testLabType.id"
              >
                {{ testLabType.name }}
              </a-select-option>
            </a-select>
            <!-- <a-input
              :disabled="submitting"
              placeholder="Masukkan Jenis Tes"
              v-decorator="['testLabType', {rules: [{ required: true, message: 'Jenis Tes harus diisi!' }]}]"
            /> -->
            <!-- {{testLab && testLab.testLabType? testLab.testLabType.name : '-'}} -->
          </a-form-item>
          <a-form-item
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            label="Dokter"
          >
            <a-select
              style="width: 220px; z-index: 99"
              show-search
              option-filter-prop="children"
              v-decorator="[
                'testlabDoctorId',
                { rules: [{ required: true, message: 'Dokter harus diisi!' }] },
              ]"
            >
              <a-select-option
                v-for="doctor in doctorList"
                :key="doctor.id"
                :value="doctor.id"
              >
                {{ doctor.fullname }}
              </a-select-option>
            </a-select>
            <!-- <a-select
              :disabled="submitting"
              v-decorator="[
                'testlabDoctorId',
                { rules: [{ required: true, message: 'Dokter harus diisi!' }] },
              ]"
              placeholder="Dokter"
            >
              <a-select-option v-for="doctor in doctorList" :key="doctor.id" :value="doctor.id">
                  {{doctor.fullname}}
               </a-select-option>
            </a-select> -->
            <!-- <a-input
              :disabled="submitting"
              placeholder="Nama Dokter"
              v-decorator="['doctor', {rules: [{ required: true, message: 'Nama dokter harus diisi!' }]}]"
            /> -->
            <!-- {{testLab && testLab.doctor? testLab.doctor.fullname : '-'}} -->
          </a-form-item>
          <a-form-item
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            label="Laboratorium"
          >
            <a-select
              :disabled="submitting"
              v-decorator="[
                'laboratoriumId',
                {
                  rules: [
                    { required: true, message: 'Laboratorium harus diisi!' },
                  ],
                },
              ]"
              placeholder="Laboratorium"
            >
              <a-select-option
                v-for="laboratorium in laboratoriumList"
                :key="laboratorium.id"
                :value="laboratorium.id"
              >
                {{ laboratorium.name }}
              </a-select-option>
            </a-select>
            <!-- {{testLab && testLab.laboratorium? testLab.laboratorium.name : '-'}} -->
          </a-form-item>
          <a-form-item
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            label="Voucher"
          >
            <a-input
              :disabled="submitting"
              placeholder="Voucher"
              v-decorator="[
                'voucherCode',
                {
                  rules: [{ required: true, message: 'Voucher harus diisi!' }],
                },
              ]"
            />
            <!-- {{testLab && testLab.voucher? testLab.voucher.code : '-'}} -->
          </a-form-item>
          <a-form-item
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            label="Hasil Tes"
          >
            <a-upload
              :multiple="false"
              v-decorator="[
                'image',
                {
                  valuePropName: 'image',
                },
              ]"
              name="upload"
              :action="action"
              list-type="picture"
              :file-list="fileList"
              @change="handleChange"
            >
              <a-button> <a-icon type="upload" /> Click to upload </a-button>
            </a-upload>
          </a-form-item>
          <button
            v-if="!testLab"
            class="btn btn-success px-5 m-1"
            :disabled="submitting"
            @click="saveTestLab"
          >
            Simpan Data Tes Lab
          </button>
          <button
            v-if="!testLab"
            class="btn btn-secondary px-5"
            :disabled="submitting"
            @click="handleCancel"
          >
            Kembali
          </button>
        </a-form>
      </div>
    </div>
    <div class="card" v-if="patientId && testLab">
      <div class="card-body">
        <h5 class="mb-4">
          <strong>Data Program</strong>
        </h5>
        <a-form :form="form" @submit="findPatient">
          <a-form-item
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            label="Kategori"
          >
            <a-radio-group
              :disabled="submitting"
              style="width: 220px; z-index: 99"
              show-search
              option-filter-prop="children"
              v-decorator="['category']"
            >
              <a-radio :value="null"> PAP </a-radio>
              <a-radio :value="'aurora'"> AURORA </a-radio>
            </a-radio-group>
          </a-form-item>
          <a-form-item
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            label="Obat"
          >
            <a-select
              :disabled="submitting"
              v-decorator="['programTypeId']"
              placeholder="Obat"
            >
              <a-select-option
                v-for="programType in programTypeList"
                :key="programType.id"
                :value="programType.id"
              >
                {{ programType.name }}
              </a-select-option>
            </a-select>
            <!-- {{program && program.programType? program.programType.name : '-'}} -->
          </a-form-item>
          <a-form-item
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            label="Dokter"
          >
            <a-select
              :disabled="submitting"
              style="width: 220px; z-index: 99"
              show-search
              option-filter-prop="children"
              v-decorator="[
                'programDoctorId',
                { rules: [{ required: true, message: 'Dokter harus diisi!' }] },
              ]"
            >
              <a-select-option
                v-for="doctor in doctorList"
                :key="doctor.id"
                :value="doctor.id"
              >
                {{ doctor.fullname }}
              </a-select-option>
            </a-select>
            <!-- <a-select
              :disabled="submitting"
              v-decorator="[
                'programDoctorId',
              ]"
              placeholder="Dokter"
            >
              <a-select-option v-for="doctor in doctorList" :key="doctor.id" :value="doctor.id">
                  {{doctor.fullname}}
               </a-select-option>
            </a-select> -->
            <!-- {{program && program.doctor? program.doctor.fullname : '-'}} -->
          </a-form-item>
          <!-- <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Farmasi">
             <a-select :disabled="submitting" style="width: 220px; z-index: 99;" show-search option-filter-prop="children" v-decorator="[
                'programDoctorId',
                { rules: [{ required: true, message: 'Farmasi harus diisi!' }] },
              ]">
              <a-select-option v-for="(doctor) in doctorList" :key="doctor.id" :value="doctor.id">
                {{doctor.fullname}}
              </a-select-option>
            </a-select>
          </a-form-item> -->
          <a-form-item
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            label="Kode Verifikasi"
          >
            <a-input
              :disabled="submitting"
              placeholder="Kode Verifikasi"
              v-decorator="['confirmationCode']"
            />
            <!-- {{program && program.doctor? program.doctor.verificationCode : '-'}} -->
          </a-form-item>
          <!-- <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Status">
             <a-select
              :disabled="submitting"
              v-decorator="[
                'programStatus',
              ]"
              placeholder="Status"
            >
              <a-select-option value="Aktif">
                  Pasien Baru
               </a-select-option>
               <a-select-option value="Perpanjang">
                  Perpanjang
               </a-select-option>
               <a-select-option value="Berhenti">
                  Berhenti
               </a-select-option>
            </a-select>
          </a-form-item> -->
          <!-- <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Alasan Berhenti">
            <a-input
              :disabled="submitting"
              placeholder="Alasan Berhenti"
              v-decorator="['terminatedMessage']"
            />
          </a-form-item> -->
          <a-form-item
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            label="Farmasi"
          >
            <a-select
              :disabled="submitting"
              style="width: 220px; z-index: 99"
              show-search
              option-filter-prop="children"
              v-decorator="['pharmacyId']"
            >
              <a-select-option
                v-for="pharmacy in pharmacyList"
                :key="pharmacy.id"
                :value="pharmacy.id"
              >
                {{ pharmacy.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            label="Resep Dokter"
          >
            <a-upload
              :multiple="false"
              v-decorator="[
                'image2',
                {
                  valuePropName: 'image2',
                },
              ]"
              name="upload"
              :action="action2"
              list-type="picture"
              :file-list="fileList2"
              @change="handleChange2"
            >
              <a-button> <a-icon type="upload" /> Click to upload </a-button>
            </a-upload>
          </a-form-item>
          <a-form-item
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            label="Status Program"
          >
            {{
              program && program.checkPoint > 5
                ? program.checkPoint == 5
                  ? program.isDrugsTaken
                    ? 'Program Berjalan'
                    : 'Pengambilan Obat'
                  : program.isTerminated
                  ? 'Program Berhenti'
                  : 'Program Perpanjang'
                : 'Pengajuan Program'
            }}
          </a-form-item>
          <button
            class="btn btn-success px-5 m-1"
            :disabled="submitting"
            @click="saveProgram"
          >
            Simpan Data Program
          </button>
          <button
            class="btn btn-secondary px-5"
            :disabled="submitting"
            @click="handleCancel"
          >
            Kembali
          </button>
        </a-form>
      </div>
    </div>
  </div>
</template>
<script>
import router from '@/router'
import { getByCode } from '@/services/axios/api/pasien'
import { getApproved } from '@/services/axios/api/dokter'
import { getAll as getAllTestLabType } from '@/services/axios/api/testlabtype'
import { getAll as getAllLaboratorium } from '@/services/axios/api/laboratorium'
import { getAll as getAllProgramType } from '@/services/axios/api/obat'
import { create as createTestLab } from '@/services/axios/api/testlab'
import { create as createProgram } from '@/services/axios/api/program'
import { getAll as getAllFarmasi } from '@/services/axios/api/farmasi'
import { BASE_URL } from '@/config'
import { notification } from 'ant-design-vue'

const labelCol = {
  xs: { span: 24 },
  sm: { span: 4 },
}
const wrapperCol = {
  xs: { span: 24 },
  sm: { span: 12 },
}
export default {
  data: function () {
    return {
      form: this.$form.createForm(this),
      labelCol,
      wrapperCol,
      cityList: [],
      pharmacyList: [],
      submitting: false,
      testLabExist: false,
      programExist: false,
      patientId: '',
      testLab: null,
      program: null,
      action: `${BASE_URL}/upload/lab-result`,
      // action: `${BASE_URL}/upload/news`,
      action2: `${BASE_URL}/upload/prescription`,
      // action2: `${BASE_URL}/upload/news`,
      fileList: [],
      fileList2: [],
      doctorList: [],
      testLabTypeList: [],
      laboratoriumList: [],
      programTypeList: [],
      checkPoint: 0,
    }
  },
  created() {
    this.$store.state.patientData = []
    this.getDoctorList()
    this.getPharmacyList()
    this.getTestLabTypeList()
    this.getLaboratoriumList()
    this.getProgramTypeList()
  },
  methods: {
    async getPharmacyList() {
      const res = await getAllFarmasi()
      this.pharmacyList = res
    },
    async getDoctorList() {
      const res = await getApproved()
      this.doctorList = res
    },
    async getTestLabTypeList() {
      const res = await getAllTestLabType()
      this.testLabTypeList = res
    },
    async getLaboratoriumList() {
      const res = await getAllLaboratorium()
      this.laboratoriumList = res
    },
    async getProgramTypeList() {
      const res = await getAllProgramType()
      this.programTypeList = res
      if (this.$route.params.id) {
        this.form.getFieldDecorator('code', { initialValue: '' })
        this.form.setFieldsValue({ code: this.$route.params.id })
        this.findPatient(null)
      }
    },
    findPatient(e) {
      this.testLabExist = false
      this.programExist = false
      this.patientId = ''
      this.fileList = []
      this.fileList2 = []
      if (e) {
        e.preventDefault()
      }
      this.form.validateFields(async (err, values) => {
        if (!err) {
          this.submitting = true
          console.log('Received values of form: ', values)
          try {
            const res = await getByCode(values.code)
            console.log(res, 'getbycode')
            this.form.getFieldDecorator('category', { initialValue: '' })
            this.form.getFieldDecorator('testLabTypeId', { initialValue: '' })
            this.form.getFieldDecorator('testlabDoctorId', { initialValue: '' })
            this.form.getFieldDecorator('laboratoriumId', { initialValue: '' })
            this.form.getFieldDecorator('voucherCode', { initialValue: '' })
            this.form.getFieldDecorator('programTypeId', { initialValue: '' })
            this.form.getFieldDecorator('programDoctorId', { initialValue: '' })
            this.form.getFieldDecorator('pharmacyId', { initialValue: '' })
            this.form.getFieldDecorator('confirmationCode', {
              initialValue: '',
            })
            this.form.getFieldDecorator('terminatedMessage', {
              initialValue: '',
            })
            this.form.getFieldDecorator('programStatus', { initialValue: '' })
            this.form.getFieldDecorator('checkPoint', { initialValue: '' })
            this.form.getFieldDecorator('image', { initialValue: '' })
            this.form.getFieldDecorator('image2', { initialValue: '' })
            this.patientId = res.id
            this.testLabExist = true
            this.programExist = true
            console.log(res)
            if (res.testLabs && res.testLabs.length) {
              this.testLabExist = true
              this.testLab = res.testLabs[0]
              if (
                res.testLabs[0].testLabEvidences &&
                res.testLabs[0].testLabEvidences.length
              ) {
                this.fileList[0] = {
                  uid: '-1',
                  name: res.testLabs[0].testLabEvidences[0].url.split('/')[
                    res.testLabs[0].testLabEvidences[0].url.split('/').length -
                      1
                  ],
                  status: 'done',
                  url: `${BASE_URL}${res.testLabs[0].testLabEvidences[0].url}`,
                  // url: `${BASE_URL}${res.testLabs[0].testLabEvidences[0].url}`,
                }
                this.form.setFieldsValue({
                  image: {
                    file: {
                      response: {
                        data: res.testLabs[0].testLabEvidences[0].url,
                      },
                    },
                  },
                })
              }
              this.form.setFieldsValue({
                testLabTypeId: res.testLabs[0].testLabType.id,
              })
              this.form.setFieldsValue({
                testlabDoctorId: res.testLabs[0].doctor
                  ? res.testLabs[0].doctor.id
                  : '-',
              })
              this.form.setFieldsValue({
                laboratoriumId: res.testLabs[0].laboratorium
                  ? res.testLabs[0].laboratorium.id
                  : '-',
              })
              this.form.setFieldsValue({
                voucherCode: res.testLabs[0].voucher
                  ? res.testLabs[0].voucher.code
                  : '-',
              })
            } else {
              this.testLab = null
            }
            if (res.programs && res.programs.length) {
              this.programExist = true
              this.program = res.programs[0]
              console.log(this.program, 'this program')
              let programStatus = 'Aktif'
              if (
                this.program &&
                this.program.checkPoint !== 6 &&
                this.program.prevProgram === null &&
                this.program.isTerminated === null
              ) {
                programStatus = 'Aktif'
              } else if (
                this.program.prevProgram &&
                this.program.isTerminated !== true
              ) {
                programStatus = 'Perpanjang'
              } else if (this.program.isTerminated) {
                programStatus = 'Berhenti'
              }
              if (
                res.programs[0].programEvidences &&
                res.programs[0].programEvidences.length
              ) {
                this.fileList2[0] = {
                  uid: '-1',
                  name: res.programs[0].programEvidences[0].url.split('/')[
                    res.programs[0].programEvidences[0].url.split('/').length -
                      1
                  ],
                  status: 'done',
                  url: `${BASE_URL}${res.programs[0].programEvidences[0].url}`,
                  // url: `${BASE_URL}${res.programs[0].programEvidences[0].url}`,
                }
                this.form.setFieldsValue({
                  image2: {
                    file: {
                      response: {
                        data: res.programs[0].programEvidences[0].url,
                      },
                    },
                  },
                })
              }
              this.form.setFieldsValue({
                programTypeId: res.programs[0].programType.id,
              })
              this.form.setFieldsValue({ category: res.programs[0].category })
              this.form.setFieldsValue({
                programDoctorId: res.programs[0].doctor.id,
              })
              this.form.setFieldsValue({
                pharmacyId: res.programs[0].pharmacy.id,
              })
              this.form.setFieldsValue({
                confirmationCode: res.programs[0].doctor.verificationCode,
              })
              this.form.setFieldsValue({
                checkPoint: res.programs[0].checkPoint,
              })
              this.form.setFieldsValue({ programStatus: programStatus })
              this.form.setFieldsValue({
                terminatedMessage: res.programs[0].terminatedMessage,
              })
              this.checkPoint = res.programs[0].checkPoint
            } else {
              console.log(this.checkPoint)
              this.checkPoint = 0
              this.program = null
            }
            this.submitting = false
          } catch (e) {
            this.submitting = false
          }
        }
      })
    },
    handleChange(info) {
      let fileList = [...info.fileList]
      fileList = fileList.slice(-1)
      fileList = fileList.map((file) => {
        if (file.response) {
          file.url = file.response.url
        }
        return file
      })

      this.fileList = fileList
    },
    handleChange2(info) {
      let fileList2 = [...info.fileList]
      fileList2 = fileList2.slice(-1)
      fileList2 = fileList2.map((file) => {
        if (file.response) {
          file.url = file.response.url
        }
        return file
      })

      this.fileList2 = fileList2
    },
    saveTestLab(e) {
      e.preventDefault()
      this.form.validateFields(async (err, values) => {
        // if (!err && !err.testLabTypeId && !err.testlabDoctorId && !err.laboratoriumId && !err.voucherCode && !err.image) {
        if (!err) {
          this.submitting = true
          try {
            values.url = values.image.file.response.data
            values.image = null
            values.image2 = null
            values.patientId = this.patientId
            values.testLabId = this.testLab ? this.testLab.id : ''
            console.log('Received values of form: ', values)
            const res = await createTestLab(values)
            if (res && res.data) {
              notification.success({
                message: 'Sukses',
                description: 'Data berhasil ditambahkan',
                duration: 5,
              })
              router.push('/pasien')
            }
            this.submitting = false
          } catch (e) {
            this.submitting = false
          }
        } else {
          console.log(err)
        }
      })
    },
    saveProgram(e) {
      e.preventDefault()
      this.form.validateFields(async (err, values) => {
        if (!err) {
          this.submitting = true
          try {
            // this.saveTestLab(e)
            console.log('ko gamasuk', values)
            values.url = values.image2 ? values.image2.file.response.data : null
            values.image = null
            values.image2 = null
            values.patientId = this.patientId
            values.programId = this.program ? this.program.id : ''
            console.log('Received values of form: ', values)
            const res = await createProgram(values)
            // console.log(values.programStatus, 'Program Status')
            // if (values.programStatus === 'Perpanjang') {
            //   console.log(this.program.id, 'this.programid')
            //   const update = await continueProgram({ id: this.program.id })
            //   if (update && update.data) {
            //     notification.success({
            //       message: 'Sukses',
            //       description: 'Pasien dalam masa perpanjangan.',
            //       duration: 5,
            //     })
            //   }
            // } else if (values.programStatus === 'Berhenti') {
            //   const update = await terminateProgramDashboard({ id: this.program.id, message: values.terminatedMessage })
            //   if (update && update.data) {
            //     notification.success({
            //       message: 'Sukses',
            //       description: 'Pasien telah diberhentikan.',
            //       duration: 5,
            //     })
            //   }
            // }
            if (res && res.data) {
              notification.success({
                message: 'Sukses',
                description: 'Data berhasil ditambahkan',
                duration: 5,
              })
              router.push('/pasien')
            }
            this.submitting = false
          } catch (e) {
            this.submitting = false
          }
        } else {
          console.log(err)
        }
      })
    },
    handleCancel(e) {
      e.preventDefault()
      router.push('/pasien')
    },
  },
}
</script>
